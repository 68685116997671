.faq {
    @apply prose px-4 py-2 md:py-8 md:px-8 lg:px-16 text-gray-800 prose-a:text-blue-600 hover:prose-a:text-blue-500;
    max-width: 95ch;
}

.faq p {
    @apply m-0 mb-0.5 text-gray-800
}

.faq p strong {
    @apply text-gray-800
}

.faq h1, h2, h3, h4 {
    @apply font-normal text-gray-800
}
