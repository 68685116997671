.react-datepicker {
    @apply bg-gray-50 border-gray-200 mr-4 z-1000
}

.react-datepicker__day {
    @apply m-0 w-8 h-8 leading-8 text-center rounded-none
}

.react-datepicker__day:hover, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    @apply bg-gray-600 text-white rounded-none
}

.react-datepicker__day--range-start, .react-datepicker__day--range-start:hover {
    @apply rounded-l-sm
}

.react-datepicker__day--range-end, .react-datepicker__day--range-end:hover {
    @apply rounded-r-sm
}
